<template>
  <div
    class="label"
    :style="`background-color: ${backgroundColor}; color: ${textColor}`"
  >
    {{ description }}
    <div v-if="readonly === false" @click="remove()">
      <i class="fas fa-times hover"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: function () {
        return "#f26522";
      },
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textColor: {
      type: String,
      required: false,
      default: function () {
        return "white";
      },
    },
    description: {
      type: String,
      required: true,
    },
  },

  methods: {
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style scoped>
.label {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding: 0 0.6em;
  font-size: 0.9rem;
  border-radius: 6px;
  line-height: 1.8;
  margin: 5px 2px 2px 5px;
}
.hover {
  opacity: 8;
  cursor: pointer;
}
</style>
